@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,300;1,400;1,700;1,900&display=swap");

@import "./styles/customize.material.scss";

* {
    padding: 0;
    margin: 0;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;

    height: 100vh;
    width: 100%;
}

